import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { IOrder, IOrders } from '../../interfaces/IOrder';
import AccountsService from '../../services/AccountsService';
import OrderPanelCard from '../OrderPanelCard/OrderPanelCard';
import IOrderPanelProps from './IOrderPanelProps';

const styles: { [key: string]: SxProps } = {
  card: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  cardContent: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    ':last-child': { paddingBottom: 2 },
  },

  list: {
    width: '100%',
    pt: 0,
    height: 'calc(100% - 36px)',
    overflow: 'auto',
    paddingBottom: 0,
  },
};

const OrderPanel: React.FC<IOrderPanelProps> = ({ account }) => {
  const [orders, setOrders] = useState<IOrders>([]);
  const abortController = new AbortController();
  const accountsService = new AccountsService(abortController.signal);

  const getOrders = async () => {
    if (account) {
      try {
        const accountIri = account['@id'];
        const [investmentOperationsWorkflows,investRequests, investOrders] = await Promise.all([
          accountsService.getInvestmentOperationWorkflows(accountIri),
          accountsService.getInvestRequests(accountIri),
          accountsService.getInvestOrders(accountIri),
        ]);

        setOrders([...(investmentOperationsWorkflows ?? []),...(investRequests ?? []), ...(investOrders ?? [])]);
      } catch (error) {
        console.debug(error);
      }
    }
  };

  useEffect(() => {
    getOrders();
    return () => abortController.abort();
  }, []);

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            <Trans>account_order_label</Trans>
          </Typography>
          <Button type="submit" variant="contained" sx={{ textTransform: 'none' }}>
            <Trans>order_new_button</Trans>
          </Button>
        </Box>

        <List sx={styles.list}>
          { orders.map((order: IOrder) => (
            <OrderPanelCard order={order} updateOrders={getOrders} key={order['@id']} />
          )) }
        </List>
      </CardContent>
    </Card>
  );
};

export default OrderPanel;
