import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/material/styles';
import i18next, { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { IAccount } from '../../interfaces/IAccount';
import IUser from '../../interfaces/IUser';
import { IFeatureFlag } from '../../interfaces/IUserFeatureFlag';
import { IUserFeatureFlag } from '../../interfaces/IUserFeatureFlag';
import SnackbarService from '../../services/SnackbarService';
import ITopBarClientState from './ITopBarClientState';
import Tag from '../Tag/Tag';
import IProviderContractType from '../../interfaces/IProviderContractType';
import IManagementMode from '../../interfaces/IManagementMode';
import ColorConstant from '../../constants/ColorConstant';

const styles: { [key: string]: SxProps } = {
  copyToClipboard: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    mr: '8px',
  },
};

class TopBarClient extends React.Component<object, ITopBarClientState> {
  public readonly state: ITopBarClientState = {
    account: undefined,
    user: undefined,
    providerContractType: undefined,
    managementMode: undefined,
    featureFlags: [],
  };

  private statusMapper: { [key: string]: { background: string; text: string } } = {
    pending: {
      background: 'teal',
      text: 'account_contract_status_pending',
    },
    rejected: {
      background: 'red',
      text: 'account_contract_status_rejected',
    },
    repurchase: {
      background: 'orange',
      text: 'account_contract_status_repurchase',
    },
    waived: {
      background: 'grey',
      text: 'account_contract_status_waived',
    },
    active: {
      background: 'primary.main',
      text: 'account_contract_status_active',
    },
  };

  constructor(props: object) {
    super(props);
    document.addEventListener('topBarClient', this.handleTopBarClientEvent);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('topBarClient', this.handleTopBarClientEvent);
  }

  public render() {
    const { account, user, providerContractType, managementMode, featureFlags } = this.state;
    const status = account?.status ? this.statusMapper[account.status] : null;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <span><Trans>{ this.buildTitle(account, user, providerContractType) }</Trans></span>
          <span style={{ fontSize: '12px' }}>
            <Trans>{ this.buildSubtitle(account) }</Trans>
          </span>
        </Box>
        {
          status && providerContractType && (
            <Tag
              title={i18next.t(`acronym_${ providerContractType.investmentWrapper.replaceAll('-', '_') }`)}
              value={i18next.t(status.text)}
              color={status.background}
            />
          )
        }
        {
          status && managementMode && (
            <Tag
              title={i18next.t(managementMode.profiled ? 'management_mode_profiled' : 'management_mode_free')}
              value={managementMode.profiled ? managementMode.name : '-'}
              color={ColorConstant.lightBlue}
            />
          )
        }
        {
          featureFlags?.map((flag: IFeatureFlag, index: number) => (
            <Tag
              key={index}
              title={t(`feature_flag_${ flag.slug }`)}
              value={flag.enabled ? 'Actif' : 'Inactif'}
              color={flag.enabled ? ColorConstant.primary : ColorConstant.theme.palette.warning.main}
            />
          ))
        }
      </Box>
    );
  }

  private buildTitle(account: IAccount | undefined, user: IUser | undefined, providerContractType: ITopBarClientState['providerContractType'] | undefined): string {
    if (account && user && providerContractType) {
      return i18next.t('account_status_client_title', {
        providerContractType: providerContractType.name,
        firstname: user.firstname,
        lastname: user.lastname?.toUpperCase() ?? ' - ',
      });
    }

    return '';
  }

  private buildSubtitle(account: IAccount | undefined): JSX.Element | string {
    if (account) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', mr: '8px' }}>
          {
            [account.id, account.reference].map((value, index) => {
              if (!value) {
                return '';
              }

              return (
                <Box key={`${ value }-${ index }`}
                  onClick={() => this.copyToClipboard(value)}
                  sx={styles.copyToClipboard}
                >
                  <span>#{ value }</span>
                  <IconButton
                    className="copy-icon"
                    size="small"
                    sx={{ ml: 0.5 }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
              );
            })
          }
        </Box>
      );
    }
    return '';
  }

  private handleTopBarClientEvent = (event: Event) => {
    const { detail: {
      account,
      user,
      providerContractType,
      managementMode,
      userFeatureFlags,
    } } = event as CustomEvent<{ account?: IAccount; user?: IUser; providerContractType?: IProviderContractType; managementMode?: IManagementMode; userFeatureFlags: IUserFeatureFlag }>;

    this.setState({
      account: account,
      user: user,
      providerContractType: providerContractType,
      managementMode,
      featureFlags: userFeatureFlags.featureFlags,
    });
  };

  private async copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
    SnackbarService.open(t('copy_to_clipboard'), 'info');
  }
}

export default TopBarClient;
