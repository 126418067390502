import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { memo } from 'react';
import { Trans } from 'react-i18next';
import IOrderPanelCardItemProps from './IOrderPanelCardItemProps';

const styles: {[key: string]: SxProps} = {
  orderContent: {
    paddingBottom: '8px !important',
    padding: '8px !important',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  amount: {
    fontSize: '18px',
    flexBasis: '25%',
    textAlign: 'right',
  },

  orderContentBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
};

const orderTypeColors = {
  buy: 'primary.main',
  sell: 'warning.main',
  exchange: 'darkGray',
  'start-monthly-investment': 'primary.main',
  'update-monthly-investment': 'darkGray',
  'stop-monthly-investment': 'warning.main',
};

const OrderPanelCardItem = ({ order, handleOpenModal }: IOrderPanelCardItemProps) => (
  <Card sx={{
    mb: 1,
    border: '1px solid',
    borderColor: order.error ? 'warning.main' : 'lightGray',
  }}
  >
    <ButtonBase sx={{ width: '100%' }} onClick={handleOpenModal}>
      <CardContent sx={styles.orderContent}>
        <Box sx={styles.orderContentBox}>
          <Typography variant="body2" fontSize="19px">
            <b><Trans>{ `order_status_${ order.status }` }</Trans></b>
          </Typography>
          <Typography variant="body2" fontSize="11px">
            <Trans>{ `order_type_${ order.type }` }</Trans>&nbsp;
            <Trans>{ order.type === 'buy' ? `order_monthly_${ order.monthlyInvestment }` : '' }</Trans>
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
          <Typography
            variant="body2"
            fontSize="19px"
            sx={{
              ...styles.amount,
              color: orderTypeColors[order.type],
            }}
          >
            <b>{ order.amountOrdered }€</b>
          </Typography>
          <Typography variant="body2" fontSize="11px">
            { order.processedAt ? (
              <>
                { format(new Date(order.processedAt as string), 'dd/MM/yyyy') } • { format(new Date(order.valuatedAt as string), 'dd/MM/yyyy') }
              </>
            ) : (
              <>
                { order.createdAt ? format(new Date(order.createdAt as string), 'dd/MM/yyyy') : '-' }
              </>
            ) }
          </Typography>
        </Box>
      </CardContent>
    </ButtonBase>
  </Card>
);

export default memo(OrderPanelCardItem);
