import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import ColorConstant from '../../constants/ColorConstant';
import { ProviderEnum } from '../../constants/ProviderEnum';
import { OrderStatus } from '../../interfaces/IOrder';
import AccountsService from '../../services/AccountsService';
import OrderService from '../../services/OrderService';
import SnackbarService from '../../services/SnackbarService';
import OrderDetailsModalList from '../OrderDetailsModalList/OrderDetailsModalList';
import IOrderDetailsModalProps from './IOrderDetailsModalProps';
import IOrderDetailsModalState from './IOrderDetailsModalState';

const styles: { [key: string]: SxProps } = {
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    minWidth: '500px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '90vh',
    overflow: 'auto',
  },

  modalHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${ ColorConstant.lightGray }`,
  },

  modalHeader: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 32px',
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    p: 2,
  },

  diplayInCenter: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    p: 2,
  },
  errorMsg: {
    padding: '8px 32px',
    fontSize: '.8rem',
    color: ColorConstant.theme.palette.warning.main,
  },
};

export default class OrderDetailsModal extends Component<IOrderDetailsModalProps, IOrderDetailsModalState> {
  public readonly state: IOrderDetailsModalState = {
    isMenuOpen: false,
    anchorEl: undefined,
    resumeBtnIsLoading: false,
    deleteBtnIsLoading: false,
  };

  private abortController = new AbortController();
  private orderService = new OrderService(this.abortController.signal);
  private accountService = new AccountsService(this.abortController.signal);

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { handleCloseModal, isOrderDetailsModalOpen, order } = this.props;

    return (
      <>
        <Modal
          open={isOrderDetailsModalOpen}
          onClose={() => handleCloseModal()}
        >
          <Card sx={styles.modalWrapper}>
            <Box sx={styles.modalHeaderWrapper}>
              <Box sx={styles.modalHeader}>
                <Typography variant="h5">
                  <Trans>{ `order_type_${ order.type }` }</Trans>&nbsp;
                  {
                    order.type === 'buy' && (
                      <Trans>{ `order_monthly_${ order.monthlyInvestment }` }</Trans>
                    )
                  }
                </Typography>
                <Typography variant="h5">
                  { order.amountOrdered }€
                </Typography>
              </Box>

              <IconButton onClick={(evt) => this.handleIsMenuOpen(true, evt)}>
                <MoreVertIcon fontSize="large" />
              </IconButton>
            </Box>

            <CardContent sx={{ p: 0 }}>
              <div>
                {
                  order.error ? (
                    <Typography sx={styles.errorMsg}>
                      <Trans>error</Trans> : { order.error }
                    </Typography>
                  ) : null
                }
              </div>
              <OrderDetailsModalList order={order} />
            </CardContent>

            <Box sx={styles.buttonWrapper}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleCloseModal()}
              >
                <Trans>close_button</Trans>
              </Button>
            </Box>
          </Card>

        </Modal>
        { this.renderMenu() }
      </>
    );
  }

  private renderMenu() {
    const { order, investmentAccounts } = this.props;
    const { anchorEl, isMenuOpen, resumeBtnIsLoading, deleteBtnIsLoading } = this.state;
    const investmentAccount = investmentAccounts && investmentAccounts[0];
    const provider = investmentAccount?.provider;
    const isResumeItemIsDisabled = !order.error && provider !== ProviderEnum.GENERALI && order.status !== OrderStatus.STATUS_PENDING;
    const isDeleteItemIsDisabled = ![
      OrderStatus.STATUS_CREATION,
      OrderStatus.STATUS_TO_SIGN,
      OrderStatus.STATUS_FAILED,
    ].includes(order.status as OrderStatus) && order['@type'] !== 'InvestmentOperationWorkflow';

    return (
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => this.handleIsMenuOpen(false)}
      >
        <MenuItem onClick={this.resumeRequest} disabled={isResumeItemIsDisabled || resumeBtnIsLoading} sx={styles.diplayInCenter}>
          {
            resumeBtnIsLoading ? <CircularProgress size={24} /> : <Trans>resume</Trans>
          }
        </MenuItem>
        <MenuItem onClick={this.deleteRequest} disabled={isDeleteItemIsDisabled || deleteBtnIsLoading} sx={styles.diplayInCenter}>
          {
            deleteBtnIsLoading ? <CircularProgress size={24} /> : <Trans>delete</Trans>
          }
        </MenuItem>
      </Menu>
    );
  }

  private handleIsMenuOpen = (opening: boolean, event?: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      isMenuOpen: opening,
      anchorEl: event?.currentTarget,
    });
  };

  private resumeRequest = async () => {
    this.setState({ resumeBtnIsLoading: true });

    try {
      const { order } = this.props;

      await this.orderService.resumeRequest(order['@id']);
      SnackbarService.open(t('success_resume_request'), 'info');
    } catch (error) {
      SnackbarService.open(t('failed_resume_request'), 'error');
      console.error(error);
    } finally {
      this.setState({
        resumeBtnIsLoading: false,
        isMenuOpen: false,
      });
    }
  };

  private deleteRequest = async () => {
    this.setState({ deleteBtnIsLoading: true });

    try {
      const { handleCloseModal, order } = this.props;

      await this.accountService.deleteInvestmentOperationWorkflow(order['@id']);
      SnackbarService.open(t('success_delete_request'), 'success');
      handleCloseModal(true);
    } catch (error) {
      const errorMsg = `${ t('failed_delete_request') } : ${ (error as AxiosError<{'hydra:description': string}>).response?.data['hydra:description'] }`;

      SnackbarService.open(errorMsg, 'error');
      console.error(error);
    } finally {
      this.setState({
        deleteBtnIsLoading: false,
        isMenuOpen: false,
      });
    }
  };
}
