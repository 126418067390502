import { IDistributionDetails } from './IDistributionDetails';
import { IOriginFundInInvestmentOperationWorkflows } from './IInvestmentOperationWorkflow';
import { IInvestOrderDetails } from './IInvestOrder';
import { IInvestRequestDetails } from './IInvestRequest';
import { IRIString, IRIType } from './IriType';

export interface IOrder extends IRIType {
  type: 'buy' | 'exchange' | 'sell' | 'start-monthly-investment' | 'update-monthly-investment' | 'stop-monthly-investment';
  monthlyInvestment: boolean;
  status: OrderStatus;
  error?: boolean | string;
  amountOrdered: number;
  amountDebited?: number;
  originFunds?: { slug: string; percent: number; date: Date | null; details?: string }[] | IOriginFundInInvestmentOperationWorkflows;
  reason?: IRIString;
  orderDetails?: IDistributionDetails | IInvestRequestDetails | IInvestOrderDetails;
  createdAt: Date | string;
  updatedAt: Date | string;
  valuatedAt?: Date | string;
  processedAt?: Date | string;
}

export type IOrders = IOrder[];

export enum OrderStatus {
  STATUS_CREATION = 'creation',
  STATUS_TO_SIGN = 'to-sign',
  STATUS_SENDING = 'sending',
  STATUS_PENDING = 'pending',
  STATUS_ON_HOLD = 'on-hold',
  STATUS_ACCEPTED = 'accepted',
  STATUS_FAILED = 'failed',
  STATUS_COMPLETED = 'completed',
  STATUS_REFUSED = 'refused'
}

export const ORDER_STATUS_MAP = {
  [OrderStatus.STATUS_CREATION]: OrderStatus.STATUS_CREATION,
  [OrderStatus.STATUS_TO_SIGN]: OrderStatus.STATUS_CREATION,
  [OrderStatus.STATUS_SENDING]: OrderStatus.STATUS_PENDING,
  [OrderStatus.STATUS_PENDING]: OrderStatus.STATUS_PENDING,
  [OrderStatus.STATUS_ON_HOLD]: OrderStatus.STATUS_ON_HOLD,
  [OrderStatus.STATUS_ACCEPTED]: OrderStatus.STATUS_ACCEPTED,
  [OrderStatus.STATUS_FAILED]: OrderStatus.STATUS_FAILED,
  [OrderStatus.STATUS_COMPLETED]: OrderStatus.STATUS_COMPLETED,
  [OrderStatus.STATUS_REFUSED]: OrderStatus.STATUS_COMPLETED,
};
