import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { Component } from 'react';
import ColorConstant from '../../constants/ColorConstant';
import { IDistributionDetail } from '../../interfaces/IDistributionDetails';
import { IInvestOrderDetail } from '../../interfaces/IInvestOrder';
import { IInvestRequestDetail } from '../../interfaces/IInvestRequest';
import IOrderDetailsModalListProps from './IOrderDetailsModalListProps';

const styles: { [key: string]: SxProps } = {
  listItemWrapper: {
    p: 0,
    borderBottom: `1px solid ${ ColorConstant.lightGray }`,
  },

  listItem: {
    padding: '8px 32px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  listItemLeftPart: {
    display: 'flex',
    flexDirection: 'column',
  },

  listItemRightPart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  typographyBig: {
    fontSize: '14px',
  },

  typographySmall: {
    fontSize: '10px',
    color: ColorConstant.gray,
  },
};

export default class OrderDetailsModalList extends Component<IOrderDetailsModalListProps> {
  public render() {
    const { order } = this.props;

    return (
      <List sx={{ p: 0 }}>
        {
          order.orderDetails?.map(
            (orderDetail) => (
              <ListItem key={orderDetail['@id']} sx={styles.listItemWrapper}>
                <Box sx={styles.listItem}>
                  <Box sx={styles.listItemLeftPart}>
                    <Typography sx={styles.typographyBig}>
                      { orderDetail.product?.name }
                    </Typography>
                    <Typography sx={styles.typographySmall}>
                      {
                        'status' in orderDetail && orderDetail.status === 'completed' ? (
                          <>
                            { format(new Date(order.processedAt as string), 'dd/MM/yyyy') } • { format(new Date(order.valuatedAt as string), 'dd/MM/yyyy') }
                          </>
                        ) : (
                          <>
                            { format(new Date(order.createdAt as string), 'dd/MM/yyyy') }
                          </>
                        )
                      }
                    </Typography>
                  </Box>
                  <Box sx={styles.listItemRightPart}>
                    <Typography sx={styles.typographyBig}>
                      { 'amountOrdered' in orderDetail ? orderDetail.amountOrdered : orderDetail.amount }€
                    </Typography>
                    { this.renderSecurityUnit(orderDetail) }
                  </Box>
                </Box>
              </ListItem>
            ),
          )
        }
      </List>
    );
  }

  private renderSecurityUnit(orderDetail: IDistributionDetail | IInvestRequestDetail | IInvestOrderDetail): JSX.Element {
    return 'status' in orderDetail && 'completed' === orderDetail.status && !orderDetail.product?.uc && orderDetail.securityUnitValue && orderDetail.securityUnitReceived ? (
      <Typography sx={styles.typographySmall}>
        { Number(orderDetail.securityUnitValue).toFixed(2) } € / part • { Number(orderDetail.securityUnitReceived).toFixed(4) } part
      </Typography>
    ) :(
      <></>
    );
  }
}

