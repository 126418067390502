import Box from '@mui/material/Box/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Trans } from 'react-i18next';
import ClientInfoContext from '../contexts/ClientInfoContext';

class FinancialCapitalPanel extends React.PureComponent {
  public render() {
    return (
      <ClientInfoContext.Consumer>
        {
          ({ financialCapital }) => {
            return (
              <Card sx={{ overflow: 'initial' }}>
                <CardContent sx={{ paddingBottom: '16px !important', height: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        <Trans>account_financial_amount_label</Trans>
                      </Typography>
                      <Typography variant="h4" sx={{ mb: 0 }}>
                        <b>{ financialCapital.amount?.toFixed(2) }€</b>
                      </Typography>
                    </Box>

                    <Box sx={{ height: '100%', width: '3px', backgroundColor: '#E9E9E9' }} />

                    <Box sx={{ flex: 2, display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                      <Box>
                        <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
                          <Trans>total_performance</Trans>
                        </Typography>
                        <Typography variant="h4" sx={{ mb: 0, textAlign: 'center' }}>
                          <b>{ financialCapital.performance ? `${ financialCapital.performance >= 0 ? '+' : '-' } ${ Math.abs(financialCapital.performance).toFixed(2) }%` : '-' }</b>
                        </Typography>
                      </Box>

                      <Box sx={{ height: '100%', width: '1px', backgroundColor: '#E9E9E9' }} />

                      <Box>
                        <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
                          <Trans>annualized_performance</Trans>
                        </Typography>
                        <Typography variant="h4" sx={{ mb: 0, textAlign: 'center' }}>
                          <b>{ financialCapital.annualizedPerformance >= 0 ? '+' : '-' } { Math.abs(financialCapital.annualizedPerformance) }%</b>
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ height: '100%', width: '3px', backgroundColor: '#E9E9E9' }} />

                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        <Trans>account_financial_invested_amount_label</Trans>
                      </Typography>
                      <Typography variant="h4" sx={{ mb: 0 }}>
                        <b>{ financialCapital.totalInvestAmount.toFixed(2) ?? 0 }€</b>
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            );
          }
        }

      </ClientInfoContext.Consumer>
    );
  }
}

export default FinancialCapitalPanel;
