import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AxiosError } from 'axios';
import { compareAsc, format } from 'date-fns';
import { t } from 'i18next';
import React from 'react';
import { formatPhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { Country, getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import fr from 'react-phone-number-input/locale/fr.json';
import { InputTypeEnum } from '../../constants/InputTypeEnum';
import { KycQuestionIdentifiersConstant } from '../../constants/KycQuestionIdentifiersConstant';
import IKycQuestion, { IKycQuestions } from '../../interfaces/IKycQuestion';
import { IReferentials } from '../../interfaces/IReferential';
import { IRIString } from '../../interfaces/IriType';
import IUserKycMppDto from '../../interfaces/UserKycMppDto/IUserKycMppDto';
import KycService from '../../services/KycService';
import SnackbarService from '../../services/SnackbarService';
import UserService from '../../services/UserService';
import IIdentityPanelModalGridItemProps, { UpdatableProperties } from './IIdentityPanelModalGridItemProps';
import IIdentityPanelModalGridItemState from './IIdentityPanelModalGridItemState';

interface IdentityAnswer {
  gender: string;
  lastName: string;
  firstName: string;
  birthName: string;
}

const styles: { [key: string]: SxProps } = {
  iconsWrapper: {
    display: 'flex',
    gap: 1,
  },

  contentWrapper: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },

  addressFromWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },

  errorMsg: {
    position: 'absolute',
    bottom: '-20px',
    left: 0,
    fontSize: 'inherit',
    color: 'inherit',
  },
};

class IdentityPanelModalGridItem extends React.Component<IIdentityPanelModalGridItemProps, IIdentityPanelModalGridItemState> {
  public readonly state: Readonly<IIdentityPanelModalGridItemState> = {
    showEditIcon: false,
    isLoading: false,
    isEditing: false,
    dateValue: new Date(this.props.content ?? ''),
    newValue: undefined,
    phoneCountry: parsePhoneNumber(this.props.content?.replaceAll(' ', '') ?? '')?.country ?? 'FR',
    errorMessage: null,
  };

  private abortController = new AbortController();
  private kycService = new KycService(this.abortController.signal);
  private userService = new UserService(this.abortController.signal);
  private fieldRef = React.createRef<HTMLInputElement>();

  public componentDidMount(): void {
    const { content } = this.props;

    this.checkValidity(content);
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { isEditable } = this.props;

    return isEditable ? this.renderEditableItem() : this.renderNonEditableItem();
  }

  private renderEditableItem() {
    const { gridXs, label, content, jsxContent } = this.props;
    const { showEditIcon, isEditing, newValue } = this.state;

    return (
      <Grid
        item
        xs={gridXs || 6}
        onMouseEnter={() => this.setState({ showEditIcon: true })}
        onMouseLeave={() => this.setState({ showEditIcon: false })}
      >
        <Typography>
          <small>{ label }</small>
        </Typography>

        {
          isEditing ?
            this.renderEditingItem()
            : (
              <Typography variant="subtitle1" sx={styles.contentWrapper}>
                <Tooltip title={newValue || jsxContent || content || ' - '}>
                  <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    { newValue || jsxContent || content || ' - ' }
                  </Box>
                </Tooltip>
                {
                  showEditIcon && (
                    <IconButton
                      size="small"
                      onClick={() => this.setState({ isEditing: true })}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
              </Typography>
            )
        }
      </Grid>
    );
  }

  private renderNonEditableItem() {
    const { gridXs, label, content, jsxContent } = this.props;

    return (
      <Grid item xs={gridXs || 6}>
        <Typography>
          <small>{ label }</small>
        </Typography>

        <Tooltip title={<>{ jsxContent || content }</>}>
          <Typography variant="subtitle1">
            { jsxContent || content }
          </Typography>
        </Tooltip>
      </Grid>
    );
  }

  private renderEditingItem() {
    const { isLoading, errorMessage } = this.state;

    return (
      <Box sx={{ position: 'relative', display: 'flex' }}>
        <Box sx={{ flex: 1 }}>
          {
            this.getRenderField()
          }
        </Box>
        <Box sx={styles.iconsWrapper}>
          {
            isLoading ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  disabled={!!errorMessage}
                  onClick={this.chooseRequest}
                  size="small"
                >
                  <CheckIcon color={!!errorMessage ? 'disabled' : 'success'} />
                </IconButton>
                <IconButton
                  onClick={() => this.setState({ isEditing: false, showEditIcon: false, errorMessage: null })}
                  size="small"
                >
                  <CloseIcon color="error" />
                </IconButton>
              </Box>
            )
          }
        </Box>
      </Box>
    );
  }

  private getRenderField(): JSX.Element {
    const { content, inputType } = this.props;
    const { newValue, errorMessage } = this.state;

    switch (inputType) {
      case InputTypeEnum.Date:
        return this.renderDateField();
      case InputTypeEnum.PhoneNumber:
        return this.renderPhoneField();
      case InputTypeEnum.Select:
        return this.renderReferentialItems();
      default:
        return (
          <TextField
            error={!!errorMessage}
            size="small"
            fullWidth
            inputRef={this.fieldRef}
            defaultValue={newValue || content}
            onChange={(event) => this.checkValidity(event.target.value)}
            helperText={errorMessage}
          />
        );
    }
  }

  private renderDateField() {
    const { propertyToUpdate } = this.props;
    const { dateValue } = this.state;
    const now = new Date();
    const hundredYearsAgo = new Date(now.setFullYear(now.getFullYear() - 100));

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          inputRef={this.fieldRef}
          renderInput={(params) => <TextField fullWidth size="small" {...params} />}
          value={dateValue}
          onChange={(e) => this.setState({ dateValue: e })}
          minDate={'birthDate' === propertyToUpdate ? hundredYearsAgo : null}
        />
      </LocalizationProvider>
    );
  }

  private renderPhoneField() {
    const { content } = this.props;
    const { newValue, phoneCountry, errorMessage } = this.state;
    const formattedPhoneNumber = formatPhoneNumber(newValue ?? content ?? '').replaceAll(' ', '') || newValue || content || '';

    return (
      <Box sx={{ display: 'flex' }}>
        <Select
          value={phoneCountry}
          size="small"
          renderValue={(value) => `+${ getCountryCallingCode(value) }`}
          onChange={(event) => this.setState({
            phoneCountry: event.target.value as Country,
          })}
        >
          { getCountries().map((country) => (
            <MenuItem key={country} value={country}>
              { fr[country] } +{ getCountryCallingCode(country) }
            </MenuItem>
          )) }
        </Select>

        <TextField
          size="small"
          error={!!errorMessage}
          fullWidth
          inputRef={this.fieldRef}
          onChange={(event) => this.checkValidity(event.target.value)}
          defaultValue={formattedPhoneNumber}
          helperText={errorMessage}
        />
      </Box>
    );
  }

  private renderReferentialItems() {
    const { referentials } = this.props as { referentials: IReferentials };

    return (
      <Select
        fullWidth
        inputRef={this.fieldRef}
        defaultValue={this.getSelectDefaultValue()}
        size="small"
      >
        {
          referentials.map((referential) => (
            <MenuItem key={referential.slug} value={referential.slug}>
              { t(referential.label) }
            </MenuItem>
          ))
        }
      </Select>
    );
  }

  private getSelectDefaultValue() {
    const { propertyToUpdate, dto } = this.props;

    const { newValue } = this.state;

    switch (propertyToUpdate) {
      case 'gender':
        return this.getGenderValueFromTranslated(newValue) || dto?.identity.gender;
      case 'maritalStatus':
        return this.getMaritalStatusReferentialValueFromTranslated(newValue) || dto?.familyInformation.maritalStatus;
      default:
        return undefined;
    }
  }

  private getMaritalStatusReferentialValueFromTranslated(newValue?: string): string {
    const { referentials } = this.props;

    return referentials?.find((ref) => ref.label === newValue)?.slug || '';
  }

  private getGenderValueFromTranslated(newValue?: string): string {
    const { referentials } = this.props;
    const slug = newValue && t(newValue) || '';

    return referentials?.find((ref) => ref.slug === slug)?.slug || '';
  }

  private chooseRequest = async () => {
    const { propertyToUpdate } = this.props;

    switch (propertyToUpdate) {
      case 'gender':
      case 'maritalStatus':
      case 'job':
      case 'firstName':
      case 'lastName':
        await this.postAnswer(propertyToUpdate);
        break;
      case 'birthDate':
        await this.postAnswer('birthDate', this.formatDate());
        break;
      case 'nbChildren':
        if (isNaN(parseInt(this.fieldRef.current?.value || ''))) {
          return SnackbarService.open(t('invalid_value'), 'error');
        }
        await this.postAnswer('nbChildren');
        break;
      case 'phoneNumber':
        const { phoneCountry } = this.state;
        const phoneNumber = parsePhoneNumber(this.fieldRef.current?.value.replaceAll(' ', '') || '', phoneCountry);

        if (!phoneNumber?.number || !isValidPhoneNumber(phoneNumber.number)) {
          return SnackbarService.open(`${ t('invalid_value') } : ${ this.fieldRef.current?.value }`, 'error');
        }

        await this.postAnswer('phoneNumber', phoneNumber.number);
        break;
      case 'email':
        await this.updateEmail();
        break;
    }
  };

  private getQuestionIri(identifier: KycQuestionIdentifiersConstant): IRIString {
    const { kycQuestions } = this.props as { kycQuestions: IKycQuestions };
    const question = kycQuestions.find((kycQuestion) => kycQuestion.identifier === identifier) as IKycQuestion;

    return question['@id'];
  }

  private getIdentifier(property: Exclude<UpdatableProperties, 'email'>): KycQuestionIdentifiersConstant {
    switch (property) {
      case 'gender':
      case 'firstName':
      case 'lastName':
        return KycQuestionIdentifiersConstant.MPP_PERSONAL_INFORMATION_NAME;
      case 'birthDate':
        return KycQuestionIdentifiersConstant.MPP_PERSONAL_INFORMATION_BIRTH;
      case 'maritalStatus':
      case 'nbChildren':
        return KycQuestionIdentifiersConstant.MPP_FAMILY_SITUATION;
      case 'job':
        return KycQuestionIdentifiersConstant.MPP_PROFESSIONAL_INFORMATION_STATUS;
      case 'phoneNumber':
        return KycQuestionIdentifiersConstant.MPP_PERSONAL_INFORMATION_PHONE;
    }
  }

  private postAnswer = async (property: Exclude<UpdatableProperties, 'email'>, forcedValue?: string) => {
    try {
      this.setState({ isLoading: true });

      const { dto } = this.props as { dto: IUserKycMppDto };
      const { setHasChangedValue, newValueTranslator } = this.props;
      const { userKyc } = dto.kycInformation;
      const questionIri = this.getQuestionIri(this.getIdentifier(property));
      const kycResponse = await this.kycService.getUserKyc(userKyc);
      const { userKycAnswers } = kycResponse.data;
      const filteredAnswers = userKycAnswers?.filter((answer) => questionIri === answer.question);

      if (filteredAnswers && filteredAnswers?.length > 0) {
        const mostRecentAnswer = filteredAnswers.sort((a, b) => compareAsc(new Date(b.createdAt), new Date(a.createdAt)))[0];
        const parsedAnswer: IdentityAnswer = JSON.parse(mostRecentAnswer.value || '');

        Object.assign(parsedAnswer, {
          [`${ property }`]: forcedValue || this.fieldRef.current?.value,
        });

        await this.kycService.postAnswer({
          question: questionIri,
          kyc: userKyc,
          valid: true,
          value: JSON.stringify(parsedAnswer),
        });

        setHasChangedValue && setHasChangedValue();

        const valueTranslated = newValueTranslator ?
          forcedValue ? newValueTranslator(forcedValue) : newValueTranslator(this.fieldRef.current?.value || '') :
          forcedValue || this.fieldRef.current?.value;

        this.setState({
          isLoading: false,
          showEditIcon: false,
          isEditing: false,
          newValue: valueTranslated,
        });
      }
    } catch (error) {
      console.debug(error);
      SnackbarService.open(t('account_update_failed'), 'error');
      this.setState({
        isLoading: false,
        showEditIcon: false,
        isEditing: false,
      });
    }
  };

  private formatDate() {
    const { dateValue } = this.state;

    return format(new Date(dateValue || ''), 'yyyy-MM-dd');
  }

  private async updateEmail(): Promise<void> {
    this.setState({ isLoading: true });

    try {
      const { userIri, setHasChangedValue } = this.props;

      await this.userService.updateEmail(userIri as string, this.fieldRef.current?.value ?? '');
      setHasChangedValue && setHasChangedValue();
    } catch (err: unknown) {
      const error = err as AxiosError;
      const data = error?.response?.data as {
        'hydra:description': string;
        violations: {
          message: string;
        }[];
      };

      console.error(error);
      let message: string | undefined = t('account_update_email_failed');

      if (data.violations?.length != null) {
        message = data.violations.pop()?.message;
      }
      SnackbarService.open(message ?? '', 'error');
      this.setState({
        isLoading: false,
        showEditIcon: false,
        isEditing: false,
      });
    }
  }

  private checkValidity(value?: string ): void {
    const { propertyToUpdate, inputType } = this.props;
    const { errorMessage } = this.state;

    let newErrorMessage: string | null = null;

    switch (inputType) {
      case InputTypeEnum.PhoneNumber:
        const { phoneCountry } = this.state;
        const phoneNumber = parsePhoneNumber(value?.replaceAll(' ', '') ?? '', phoneCountry);

        if (!isValidPhoneNumber(phoneNumber?.number ?? '', phoneCountry)) {
          newErrorMessage = newErrorMessage ?? 'invalid_phone_number';
        }
    }

    switch (propertyToUpdate) {
      case 'job':
        if (!value) {
          newErrorMessage = 'this_field_is_required';
        } else {
          if (value.length < 3) {
            newErrorMessage = 'job_name_sould_be_3_min_length';
          }
          if (value.length > 60) {
            newErrorMessage = newErrorMessage ?? 'job_name_sould_be_60_max_length';
          }
        }

        break;
      case 'email':
        if (!value) {
          newErrorMessage = newErrorMessage ?? 'this_field_is_required';
        }

        break;
    }

    if (newErrorMessage !== errorMessage) {
      this.setState({
        errorMessage: newErrorMessage ? (
          <Typography component="span" sx={styles.errorMsg}>
            { t(newErrorMessage) }
          </Typography>
        ) : null,
      });
    }
  }
}

export default IdentityPanelModalGridItem;
