import { InvestmentOperationType } from '../constants/investment-operation-type';
import { INVESTMENT_OPERATION_PLACES_WORKFLOW } from '../constants/investment-operation-workflow-places';
import { IDistributionDetails } from './IDistributionDetails';
import { IInvestmentOperationPlaceData } from './IInvestmentOperationPlaceData';
import { InvestmentCompartment } from './IMppChoice';
import { IRIString, IRIType } from './IriType';

export interface IInvestmentOperationWorkflow extends IRIType {
  id: string;
  place: INVESTMENT_OPERATION_PLACES_WORKFLOW;
  placeData: IInvestmentOperationPlaceData;
  investmentCompartment: InvestmentCompartment;
  buyDistribution: IDistributionDetails; // empty array if profiled Management Mode
  sellDistribution: IDistributionDetails; // empty array if profiled Management Mode
  originFunds: IOriginFundInInvestmentOperationWorkflows;
  taxOption?: string;
  reason?: IRIString;
  reasonDetail?: string;
  userInvestmentAccount: IRIString;
  amount: number;
  type: InvestmentOperationType;
  stopDuration?: number;
  frequency: string;
  abandoned: boolean;
  createdAt: string;
  updatedAt: string;
}

export type IInvestmentOperationWorkflows = IInvestmentOperationWorkflow[];

interface IOriginFundInInvestmentOperationWorkflow {
  date?: Date;
  percent: number;
  origin: string;
  details?: string;
}

export type IOriginFundInInvestmentOperationWorkflows = IOriginFundInInvestmentOperationWorkflow[];

export const INVESTMENT_OPERATION_TYPE_MESSAGE = {
  [InvestmentOperationType.TYPE_FREE_INVESTMENT]: 'Versement libre',
  [InvestmentOperationType.TYPE_PARTIAL_SELL]: 'Rachat partiel',
  [InvestmentOperationType.TYPE_TOTAL_SELL]: 'Rachat total',
  [InvestmentOperationType.TYPE_ARBITRAGE]: 'Arbitrage',
  [InvestmentOperationType.TYPE_START_INVESTMENT]: 'Démarrage de VLP',
  [InvestmentOperationType.TYPE_UPDATE_INVESTMENT]: 'Mise à jour du VLP',
  [InvestmentOperationType.TYPE_STOP_INVESTMENT]: 'Arrêt de VLP',
};

export const INVESTMENT_OPERATION_WORKFLOW_TYPES = {
  [InvestmentOperationType.TYPE_FREE_INVESTMENT]: 'buy',
  [InvestmentOperationType.TYPE_ARBITRAGE]: 'exchange',
  [InvestmentOperationType.TYPE_PARTIAL_SELL]: 'sell',
  [InvestmentOperationType.TYPE_TOTAL_SELL]: 'sell',
  [InvestmentOperationType.TYPE_START_INVESTMENT]:'start-monthly-investment',
  [InvestmentOperationType.TYPE_UPDATE_INVESTMENT]: 'update-monthly-investment',
  [InvestmentOperationType.TYPE_STOP_INVESTMENT]: 'stop-monthly-investment',
};
